<template>
  <b-container fluid>
    <!-- <b-breadcrumb
      class="breadcrumb pb-2 pt-1 noprint"
      :items="breadcrumb_items"
    ></b-breadcrumb> -->
    <br />
    <b-tabs class="mt-3" v-model="tab_index">
      <b-tab
        :title="`${$getVisibleNames(
          'mesh.egressprofilematter',
          true,
          'Asignaturas'
        )}`"
      >
        <MatterList class="mt-4" :allows_crud="allows_crud"> </MatterList>
      </b-tab>
      <b-tab
        :title="$getVisibleNames('teaching.matter', true, 'Asignaturas Base')"
      >
        <template v-if="load_old_matter">
          <OldMatterAltList class="mt-4" :allows_crud="allows_crud">
          </OldMatterAltList>
          <!-- <OldMatterList class="mt-4" :allows_crud="allows_crud">
          </OldMatterList> -->
        </template>
      </b-tab>
      <b-tab
        v-if="
          institution &&
          institution.internal_use_id == 'duoc_uc' &&
          ((user_position &&
            [1, 2].includes(user_position.position) &&
            [1, 2].includes(user.groups[0])) ||
            user.is_superuser)
        "
        :title="
          $getVisibleNames(
            'teaching.duocsapmatter',
            true,
            'Asignaturas creadas en SAP'
          )
        "
      >
        <DuocSapMatterList class="mt-4" :allows_crud="allows_crud">
        </DuocSapMatterList>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "MatterListView",
  components: {
    OldMatterAltList: () =>
      import("@/components/mesh/CurricularResources/Matter/OldMatterAltList"),
    DuocSapMatterList: () =>
      import(
        "@/components/teaching/CurricularResources/DuocSapMatter/DuocSapMatterList"
      ),
    // OldMatterList: () =>
    //   import("@/components/mesh/CurricularResources/Matter/OldMatterList"),
    MatterList: () =>
      import("@/components/mesh/CurricularResources/Matter/MatterList"),
  },
  props: {
    display_id: {},
  },
  data() {
    return {
      tab_index: 0,
      load_old_matter: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      user_position: names.USER_POSITION,
    }),
    allows_crud() {
      if (this.user && this.user.is_superuser) {
        const has_permission = this.$hasPermissions(
          "mesh.add_egressprofilematter"
        );
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else return false;
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    // breadcrumb_items() {
    //   return [
    //     { text: "Productos Curriculares", active: true },
    //     {
    //       text: this.$getVisibleNames(
    //         "mesh.egressprofilematter",
    //         true,
    //         "Asignaturas"
    //       ),
    //       active: true,
    //     },
    //   ];
    // },
  },
  methods: {},
  created() {
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    this.$store.dispatch(names.FETCH_MATTER_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_MATTER_CONTRIBUTIONS);
    this.$store.dispatch(names.FETCH_FORMATION_LINES);
    this.$store.dispatch(names.FETCH_REGIMES);
    this.$store.dispatch(names.FETCH_MODALITIES);
  },
  watch: {
    tab_index: function () {
      if (this.tab_index == 1) {
        this.load_old_matter = true;
      }
    },
  },
};
</script>
<style scoped>
.table-title {
  font-size: var(--primary-font-size);
}
.b-card-style {
  background: var(--primary-color);
}
.th-style {
  background: var(--secondary-color) !important;
  color: var(--secondary-font-color);
}

.modal th {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  font-weight: normal;
}
p {
  text-align: justify;
  margin-left: 2px;
  padding-left: 12px;
  margin-right: 2px;
  padding-right: 12px;
  font-size: var(--secondary-font-size);
}
td[colspan="5"] {
  max-width: 35rem;
}
td {
  vertical-align: middle;
  text-align: center;
}

.campus-div {
  background-color: var(--secondary-color);
  color: white;
  width: auto;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1 !important;
  content: "❯ " !important;
  padding: 0;
  padding-right: 0.65em;
}
</style>